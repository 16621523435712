// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-dark {
  color: var(--ion-color-dark) !important;
}

.border-round {
  border-radius: 10px;
}

.text-indent-10 {
  text-indent: 10px;
}

.d-flex {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/ui/info-banner-alert/info-banner-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".color-dark {\r\n  color: var(--ion-color-dark) !important;\r\n}\r\n\r\n.border-round {\r\n  border-radius: 10px;\r\n}\r\n\r\n\r\n.text-indent-10 {\r\n  text-indent: 10px;\r\n}\r\n\r\n.d-flex {\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
