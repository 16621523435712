// External Module
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// module datepicker material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// Module
import { HeaderModalModule } from '../header-modal/header-modal.modal';
import { FilterHourModule } from './components/share/filter-hour/filter-hour.module';
import { FilterDateInputModule } from './components/share/filter-date-input/filter-date-input.module';
import { DatePickerMaterialModule } from './components/share/datepicker-material/datepicker-material.module';

// Components
import {
  FilterDateComponent,
  FilterDateDobleHourInModalComponent,
  FilterDateModalComponent,
  FilterDateModalHourComponent,
  FilterDateWithHoursComponent,
  FilterDoubleHourSelectedComponent,
  FilterPeriodComponent
} from './';

// Services
import { LanguageService } from '../../services/languages.service';
import { MatLanguageService } from '../../services/mat-language/mat-language.service';

// Pipe
import { DateStringLanguagePipe } from '../../pipes/date-string-language/date-string-language.pipe';
import { DateStringLanguagePipeModule } from '../../pipes/date-string-language/date-string-language-pipe.module';


@NgModule({
  declarations: [
    FilterDateComponent,
    FilterDateModalComponent,
    FilterDateModalHourComponent,
    FilterDateWithHoursComponent,
    FilterPeriodComponent,
    FilterDateDobleHourInModalComponent,
    FilterDoubleHourSelectedComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,

    DatePickerMaterialModule,
    DateStringLanguagePipeModule,
    FilterDateInputModule,
    FilterHourModule,
    HeaderModalModule
  ],
  providers: [LanguageService, DatePipe, DateStringLanguagePipe,
    MatDatepickerModule,
    MatNativeDateModule,
    MatLanguageService],
  exports: [
    DatePickerMaterialModule,
    FilterHourModule,
    FilterDateComponent,
    FilterDateModalComponent,
    FilterDateModalHourComponent,
    FilterDateDobleHourInModalComponent,
    FilterDoubleHourSelectedComponent,
    FilterDateWithHoursComponent,
    FilterPeriodComponent
  ],
})
export class FilterDateTemplateModule {}
