// Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { InfoBannerAlertComponent } from './info-banner-alert.component';

// Services
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
    declarations: [
      InfoBannerAlertComponent
    ],
    imports: [
      CommonModule,
      TranslateModule,
      IonicModule
    ],
    providers: [
      LanguageService,
      SkinMangementService
    ],
    exports: [
      InfoBannerAlertComponent
    ]
  })
  export class InfoBannerAlertModule { }
