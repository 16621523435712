import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import { Company } from 'src/app/models';

// utils
import { substringHTMLWith3Points } from 'src/app/utils';

@Component({
  selector: 'app-filter-table-template',
  templateUrl: './filter-table-template.component.html',
  styleUrls: ['./filter-table-template.component.scss'],
})
export class FilterTableTemplateComponent implements OnInit {

  @Input() company: Company;
  @Input() loading: boolean = false;
  @Input() filterText: string = '';
  @Input() filterTranslate: string = '';
  @Input() totalElements: number = 0;

  @Input() maxLengthFilterText: number = null;

  @Output() showFilter = new EventEmitter();

  substringHTMLWith3Points = substringHTMLWith3Points;

  constructor() { }

  ngOnInit() {}

  showFilterHandle(){
    this.showFilter.emit()
  }

}
