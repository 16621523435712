// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-dark {
  color: var(--ion-color-dark);
}

.font-title {
  font-size: 1.2em;
}

.border-dark {
  border: 1px solid var(--ion-color-medium);
  box-shadow: 0px 0px 17px 2px rgba(128, 128, 128, 0.8);
}

.indent-3 {
  text-indent: 3%;
}

.d-block {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/legends/ifree-vend-legend-share/ifree-vend-legend-share.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,yCAAA;EACA,qDAAA;AACF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,cAAA;AAAF","sourcesContent":[".color-dark{\r\n  color: var(--ion-color-dark)\r\n}\r\n\r\n.font-title{\r\n  font-size: 1.2em;\r\n}\r\n\r\n.border-dark {\r\n  border: 1px solid var(--ion-color-medium);\r\n  box-shadow: 0px 0px 17px 2px rgb(128, 128, 128, 0.8);\r\n}\r\n\r\n\r\n.indent-3{\r\n  text-indent: 3%;\r\n}\r\n\r\n.d-block{\r\n  display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
