// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Module
import { InfoBannerAlertModule } from './info-banner-alert';

// Components
import { DoubleLabelVisibilityComponent, InputCompanyComponent } from './';

// Services
import { LanguageService } from '../../services/languages.service';

@NgModule({
    declarations: [
      DoubleLabelVisibilityComponent,
      InputCompanyComponent,
    ],
    imports: [
      CommonModule,
      TranslateModule,
      ReactiveFormsModule,
      FormsModule,
      IonicModule,

      InfoBannerAlertModule
    ],
    providers: [
      LanguageService,
    ],
    exports: [
      InfoBannerAlertModule,

      DoubleLabelVisibilityComponent,
      InputCompanyComponent,
    ]
  })
  export class UICustomModule { }
