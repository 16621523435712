import { Component, Input, OnInit } from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-info-banner-alert',
  templateUrl: './info-banner-alert.component.html',
  styleUrls: ['./info-banner-alert.component.scss'],
})
export class InfoBannerAlertComponent implements OnInit {

  @Input() company: Company;
  @Input() fontSize: number = 1.2;
  @Input() usePadding: boolean = true;

  constructor() { }

  ngOnInit() {}

}
