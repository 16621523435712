// External modules
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import { FilterTableTemplateComponent } from './filter-table-template.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    HttpClientModule,
  ],
  declarations: [
    FilterTableTemplateComponent
  ],
  providers: [LanguageService, SkinMangementService, DatePipe],
  exports: [
    FilterTableTemplateComponent
  ],
})
export class FilterTableTemplateModule {}
