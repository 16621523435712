import { Component, OnInit } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  SkinMangementService,
  PermissionService,
} from '../../../services/';
import { LanguageService, FixMenuService } from '../../services/';
import { MainMenuService } from './service/';

// Models
import {
  Permission,
  Company,
  LinkRouteGroup,
  LinkRoute,
} from '../../../models';
import { GroupsNameRouteValids } from './models';

// Utils
import {
  type,
  pcWidth,
  MULTI_LOGIN_ROUTE,
  isAllianceDomain,
  CREATE_TICKET_URL,
  CHECK_TICKET_URL,
  haveDashboard,
} from '../../../utils/';
import {
  NOT_ORDER_GROUP_ROUTES,
  ORDER_VISIBILITY_ROUTES,
  GROUP_NAME_ROUTES_DB,
  GroupNameRoutesDB,
} from './constant';

// Factories
import {
  AenaTranslates,
  ApplicationManagementRoutesTranslates,
  ArticlesRoutesTranslates,
  DataManagerRoutesTranslates,
  OnlineShopRoutesTranslates,
  QRRoutesTranslates,
  QueryRoutesTranslates,
  UserManagementRoutesTranslates,
  getAenaRoutes,
  getApplicationManagementRoutes,
  getArticlesRoutes,
  getDataManagerRoutes,
  getOnlineShopRoutes,
  getQRRoutes,
  getQueryRoutes,
  getUserManagementRoutes,
  TranslateMachineRoutes,
  getRoutesMachines,
  ClickCollectRoutesTranslates,
  getClickCollectRoutes,
  ReportRoutesTranslates,
  getReportRoutes,
  RoutesManagementRoutesTranslates,
  getRoutesManagementRoutes,
  HorecaQueryRoutesTranslate,
  getHorecaQueryRoutes,
  BillingRoutesTranslates,
  getBillingRoutes,
  getCardRoutes,
  getDashboardRoutes,
  DashboardTranslates,
  DocumentationRoutesTranslates,
  getDocumentation,
} from './factories/get-routes';
import {
  generateRoutesGroupValid,
  setGroupRoutes,
  startGroupRoutes,
} from './factories';
import { getQPayRoutes, QPayRoutesTranslates } from './factories/get-routes/get-qpay-routes';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  company: Company;
  type = type;

  nameUser = '';

  // Search
  searchText = '';
  routeSearch: LinkRoute[] = [];

  pcWidth = pcWidth;
  isFixMenu: boolean = false;
  started: boolean = false;

  // ----------------------- routes -----------------------

  // routes without group
  routesWithoutGroup: LinkRoute[] = [];
  routeWihtoutShow: LinkRoute[] = [];

  // routes group
  cardsRoutes: LinkRoute[] = [];

  // todas las routes group
  routeGroup: LinkRouteGroup[] = [];

  groupRoutes: Record<GroupsNameRouteValids, LinkRoute[]> = {
    query: [],
    cards: [],
    machines: [],
    articles: [],
    dataManager: [],
    qr: [],
    onlineShop: [],
    userManagement: [],
    clickCollect: [],
    applicationManagement: [],
    aena: [],
    listing: [],
    routesManagement: [],
    horeca: [],
    billing: [],
    dashboards: [],
    documentation: [],
    qPay: []
  };

  routesValid: Record<GroupsNameRouteValids, number[]>;

  // ----------------------- translates -----------------------

  searchTranslate = '';

  // translate routes without group
  // homeTranslate = '';
  profileTranslate = '';

  // group routes
  groupRouteTranslates: Record<GroupsNameRouteValids, string> = {
    query: '',
    cards: '',
    machines: '',
    articles: '',
    dataManager: '',
    qr: '',
    onlineShop: '',
    userManagement: '',
    clickCollect: '',
    applicationManagement: '',
    aena: '',
    listing: '',
    routesManagement: '',
    horeca: '',
    billing: '',
    dashboards: '',
    documentation: '',
    qPay: ''
  };

  // translates routes

  queryRoutesTranslates: QueryRoutesTranslates = {
    transactionQuery: '',
  };

  // translate cards
  registerCardsTranslate = '';
  assignCardsTranslate = '';
  registerCardRouteTranslate = '';
  isRegisterCardTranslate: boolean = true;

  cardsRoutesTranslate = {
    tagStatus: '',
    cardActivity: '',
    subsidies: '',
    loadCredit: '',
    registerOrAssignCards: '',
    checkCard: '',
    onlineLoad: '',
    refill: '',
    refunds: '',
    transactions: '',
    appSupport: '',
  };

  translatesMachines: TranslateMachineRoutes = {
    machineStatus: '',
    tillMapping: '',
    realtimesale: '',
    machineList: '',
    site: '',
    qrRequest: '',
    ticketCheck: '',
    inventory: '',
    inventoryManager: '',
    ocsAlerts: '',
    tillsAlerts: '',
    siteElora: '',
    iFreeVending: ''
  };

  articlesRoutesTranslates: ArticlesRoutesTranslates = {
    soldItems: '',
    top5sales: '',
  };

  dataManagerRoutesTranslates: DataManagerRoutesTranslates = {
    operatorGroup: '',
    operator: '',
    clientGroup: '',
    client: '',
    site: '',
    till: '',
    article: '',
    supervisor: '',
    routeDriver: '',
    route: ''
  };

  qrRoutesTranslates: QRRoutesTranslates = {
    generateQr: '',
    checkQr: '',
  };

  onlineShopRoutesTranslates: OnlineShopRoutesTranslates = {
    onlineShop: '',
    ecommerceManager: '',
    checkOrders: '',
    ordersManager: '',
  };

  userManagementRoutesTranslates: UserManagementRoutesTranslates = {
    accessWeb: '',
    accessQuery: '',
    accessWebClient: '',
  };

  clickCollectRoutesTranslates: ClickCollectRoutesTranslates = {
    clickCollect: '',
  };

  applicationManagementRoutesTranslates: ApplicationManagementRoutesTranslates = {
    imageManager: '',
    notificationPanel: '',
    notificationManager: '',
    appFailedCharges: '',
    licensesVtagManager: '',
    requestLicensesVtag: ''
  };

  aenaTranslates: AenaTranslates = {
    assignArticleFamiliesAena: '',
    downloadFileAena: '',
    tillMapping: '',
    sendFilesAena: '',
    checkArticleAena: '',
    transactionQuery: '',
    articlesManagerAena: '',
    checkSubgroupArticleAena: '',
  };

  reportRoutesTranslates: ReportRoutesTranslates = {
    reports: '',
  };

  routesRoutesTranslates: RoutesManagementRoutesTranslates = {
    salesModule: '',
    machinesModule: '',
  };

  horecaQueryRoutesTranslate: HorecaQueryRoutesTranslate = {
    horecaQuery: '',
    horecaDashboard: ''
  };

  billingRoutesTranslates: BillingRoutesTranslates = {
    turnOver: '',
    collectionQuery: ''
  };

  dashboardRourtesTranslate:DashboardTranslates = {
    welcome: '',
    refilling: '',
    statusNet: ''
  }

  documentationRoutesTranslate: DocumentationRoutesTranslates = {
    documnentation: '',
    newsAlliance: '',
    newsNoPartner: '',
    newsPartner: ''
  }

  qPayRoutesTranslates: QPayRoutesTranslates = {
    transactionsQPay: '',
    terminalsQPay: ''
  }

  loadingTranslate = true;

  loadingPermissions = true;

  // order visibility
  orderVisibilityRoutes = ORDER_VISIBILITY_ROUTES;

  // support routes
  createTicket = false;
  checkTicket = false;
  supportCustomer = false;
  createTicketTranslate = '';
  checkTicketTranslate = '';
  supportCustomerTranslate = '';
  createTicketUrl = CREATE_TICKET_URL;
  checkTicketUrl = CHECK_TICKET_URL;

  locale = 'es-ES';

  // multi login
  isMultiLogin: boolean = false;
  isAllianceDomain = isAllianceDomain;
  MULTI_LOGIN_ROUTE = MULTI_LOGIN_ROUTE;
  routeActive: string = '';

  // subscription
  permissionsChange$: Subscription;
  languageChange$: Subscription;
  loginMenu$: Subscription;
  fixMenuChange$: Subscription;
  profileMenu$: Subscription;
  haveMultiLogin$: Subscription;
  routerEvents$: Subscription;

  constructor(
    public readonly platform: Platform,
    public readonly router: Router,
    private navCtrl: NavController,
    private _authService: AuthService,
    private _languageService: LanguageService,
    private translate: TranslateService,
    private skinService: SkinMangementService,
    private _permissionService: PermissionService,
    private _menuService: MainMenuService,
    private _fixMenu: FixMenuService
  ) {}

  async ngOnInit() {
    this.company = this.skinService.getCompany();

    if (this.company) {
      this.started = true;
    }

    this.setRoutes();

    this.permissionChange();

    this.savePlaceholders();
    this.languageChange$ = this._languageService.languageChange.subscribe(
      (value) => {
        this.translate.setDefaultLang(value);
        this.savePlaceholders();
      }
    );

    this.nameUser =
      (await this._authService.getNameLanguage(this.locale)) || '';

    this.startRouteGroup();
    this.getSupportPermission();

    this.profileMenu();

    this.loginMenu();

    this.fixMenuHandle();

    this.routeHandle();

    this.multiLoginHandle();
  }

  ngOnDestroy(): void {
    if (this.permissionsChange$) this.permissionsChange$.unsubscribe();
    if (this.languageChange$) this.languageChange$.unsubscribe();
    if (this.loginMenu$) this.loginMenu$.unsubscribe();
    if (this.fixMenuChange$) this.fixMenuChange$.unsubscribe();
    if (this.profileMenu$) this.profileMenu$.unsubscribe();
    if (this.haveMultiLogin$) this.haveMultiLogin$.unsubscribe();
    if (this.routerEvents$) this.routerEvents$.unsubscribe();
  }

  getNameUser() {
    setTimeout(() => {
      this._authService.getNameLanguage(this.locale).then((newName) => {
        this.nameUser = newName;
      });
    }, 200);
  }

  logout() {
    this.nameUser = '';
    this.createTicket = false;
    this.checkTicket = false;
    this.supportCustomer = false;
    this.isMultiLogin = false;
    this._menuService.removeIdle();
    this._authService.logout();
  }

  permissionChange() {
    this.permissionsChange$ = this._permissionService.permissionsChange.subscribe(
      (res: Permission[]) => {
        //console.log('permissions', this.permissions);

        this.getValidsRoutes(res);
        this.getSupportPermission();

        this.loadingPermissions = false;
      }
    );
  }

  profileMenu() {
    this.profileMenu$ = this._menuService.profileMenu.subscribe((res) => {
      if (res) {
        this.locale !== 'en-EN'
          ? (this.nameUser = `${res.name} ${res.surname}`)
          : (this.nameUser = `${res.surname}, ${res.name}`);
      }
    });
  }

  loginMenu() {
    this.loginMenu$ = this._menuService.loginMenu.subscribe((res) => {
      if (res) {
        this.nameUser = '';
        this.getNameUser();
        this.startRouteGroup();
        this.getSupportPermission();
      } else {
        this._authService.getNameLanguage(this.locale).then((nameUser) => {
          this.nameUser = nameUser;
        });
      }
    });
  }

  multiLoginHandle() {
    this._authService.haveMultiLogin();
    this.haveMultiLogin$ = this._authService.haveMultiLogin$.subscribe(
      (res) => {
        // console.log('multi login', res)
        this.isMultiLogin = res;
      }
    );
  }

  fixMenuHandle() {
    this.isFixMenu = this._fixMenu.getMenuFix();
    this.fixMenuChange$ = this._fixMenu.fixMenuChange.subscribe((res) => {
      this.isFixMenu = res;
      this._menuService.sendChangeFixMenu(res);
    });
  }

  routeHandle() {
    this.routerEvents$ = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.routeActive = this.router.url;
      }
    });
  }

  searchChange(event) {
    this.routeSearch = [];
    if (event && this.started) {
      for (let pos of this.routeGroup) {
        let routeSearchParcial = pos.linkRoute.filter((route) =>
          this.compareTextInsensitive(route.name, event)
        );
        this.routeSearch.push(...routeSearchParcial);
      }
      // if (this.compareTextInsensitive(this.homeTranslate, event)) {
      //   this.routeSearch.push({
      //     route: '/user/home',
      //     name: this.homeTranslate,
      //   });
      // }

      if (this.compareTextInsensitive(this.profileTranslate, event)) {
        this.routeSearch.push({
          route: '/user/profile',
          name: this.profileTranslate,
        });
      }

      if (
        this.compareTextInsensitive(this.createTicketTranslate, event) &&
        this.createTicket
      ) {
        this.routeSearch.push({
          route: this.createTicketUrl,
          name: this.createTicketTranslate,
        });
      }

      if (
        this.compareTextInsensitive(this.checkTicketTranslate, event) &&
        this.checkTicket
      ) {
        this.routeSearch.push({
          route: this.checkTicketUrl,
          name: this.checkTicketTranslate,
        });
      }

      if (
        this.compareTextInsensitive(this.supportCustomerTranslate, event) &&
        this.supportCustomer
      ) {
        this.routeSearch.push({
          route: '/user/customer-support',
          name: this.supportCustomerTranslate,
        });
      }

      this.routeSearch.sort((a: LinkRoute, b: LinkRoute) =>
        a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
          ? 1
          : b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
          ? -1
          : 0
      );
    }
  }

  savePlaceholders() {
    this.translate
      .get([
        // 'menu.home',
        'menu.profile',
        'menu.search',
        'menu.transaction',
        'menu.transactions_query',
        'menu.micromarket_query',
        'menu.webloads_query',
        'menu.cards',
        'menu.articles',
        'menu.tag_status',
        'menu.card_activity',
        'menu.machines_status',
        'menu.machines',
        'menu.subsidies',
        'menu.subsidy_setup',
        'menu.balance_loading',
        'menu.till_mapping',
        'menu.real_time_sale',
        'menu.machines_list',
        'menu.till_charge',
        'menu.failures',
        'menu.sold_items',
        'menu.top5_sales',
        'operator_group',
        'operator',
        'customer_group',
        'customer',
        'site',
        'till',
        'menu.create_edit',
        'menu.amount_transfer',
        'menu.load_credit',
        'menu.load_credit_unlimited',
        'menu.create_support_ticket',
        'menu.check_support_ticket',
        'menu.customer_support',
        'locale',
        'menu.qr_request',
        'menu.register_user',
        'menu.register_user2',
        'menu.check_card2',
        'menu.check_card',
        'menu.online_load',
        'menu.refill',
        'menu.transactions',
        'menu.qr',
        'menu.generateQr',
        'menu.checkQr',
        'menu.onlineShop',
        'menu.user_management',
        'menu.access_web',
        'menu.assign_user',
        'menu.assign_user2',
        'menu.access_web_client',
        'menu.ap',
        'menu.access_query',
        'menu.ecommerce_manager',
        'menu.check_orders',
        'menu.orders_manager',
        'menu.refunds',
        'menu.ticket_check',
        'menu.click_collect',
        'menu.click_collect_group',
        'menu.inventory',
        'menu.inventory_manager',
        'menu.turn_over',
        'menu.application_management',
        'menu.image_manager',
        'menu.notification_panel',
        'menu.notification_manager',
        'menu.ocs_alerts',
        'menu.assign_articles_families_aena',
        'menu.aena',
        'menu.download_files_aena',
        'menu.data_manager',
        'menu.article_manager',
        'menu.operator_group_manager',
        'menu.operator_manager',
        'menu.client_manager',
        'menu.site_manager',
        'menu.till_manager',
        'menu.client_group_manager',
        'menu.app_failed_charges',
        'menu.listings',
        'menu.reports',
        'menu.sales_module',
        'menu.machines_module',
        'menu.send_file_aena',
        'menu.article_manager_aena',
        'menu.check_article_aena',
        'menu.check_subgroup_article_aena',
        'till_alert.title',
        'menu.route_management',
        'menu.horeca',
        'menu.horeca_query',
        'menu.billing',
        'menu.dashboard',
        'menu.dashboard_welcome',
        'menu.site_elora',
        'menu.horeca_dashboard',
        'menu.refilling_dashboard',
        'menu.documentation',
        'menu.documentation_route',
        'menu.ifree_vend_manager',
        'menu.status_net',
        'menu.supervisor_manager',
        'menu.stocker_manager',
        'menu.route_manager',
        'menu.request_licenses_vtag',
        'menu.licenses_vtag_manager',
        'menu.news_alliance',
        'menu.news_no_partner',
        'menu.news_partner',
        'menu.qpay',
        'menu.transactions_qpay',
        'menu.terminals_qpay',
        'menu.collection_query'
      ])
      .subscribe((translates) => {

        this.locale = translates['locale'];

        this._authService.getNameLanguage(this.locale).then((changeName) => {
          this.nameUser = changeName;
        });

        this.searchText = '';
        this.searchChange('');

        this.searchTranslate = translates['menu.search'];

        this.groupRouteTranslates = {
          query: translates['menu.transaction'],
          cards: translates['menu.cards'],
          machines: translates['menu.machines'],
          articles: translates['menu.articles'],
          dataManager: translates['menu.data_manager'],
          qr: translates['menu.qr'],
          onlineShop: translates['menu.onlineShop'],
          userManagement: translates['menu.user_management'],
          clickCollect: translates['menu.click_collect_group'],
          applicationManagement: translates['menu.application_management'],
          aena: translates['menu.aena'],
          listing: translates['menu.listings'],
          routesManagement: translates['menu.route_management'],
          horeca: translates['menu.horeca'],
          billing: translates['menu.billing'],
          dashboards: translates['menu.dashboard'],
          documentation: translates['menu.documentation'],
          qPay: translates['menu.qpay']
        };

        // routes
        // this.homeTranslate = translates['menu.home'];
        this.profileTranslate = translates['menu.profile'];

        this.queryRoutesTranslates = {
          transactionQuery: translates['menu.transactions_query'],
        };

        // cards
        this.registerCardsTranslate =
          this.company.nameCard == 'AlliancePay'
            ? translates['menu.register_user']
            : translates['menu.register_user2'];
        this.assignCardsTranslate =
          this.company.nameCard == 'AlliancePay'
            ? translates['menu.assign_user']
            : translates['menu.assign_user2'];
        this.getRegisterCardRouteTranslate();
        this.cardsRoutesTranslate = {
          tagStatus: translates['menu.tag_status'],
          cardActivity: translates['menu.card_activity'],
          subsidies: translates['menu.subsidies'],
          loadCredit: translates['menu.load_credit'],
          registerOrAssignCards: this.registerCardRouteTranslate,
          checkCard:
            this.company.nameCard == 'AlliancePay'
              ? translates['menu.check_card2']
              : translates['menu.check_card'],
          onlineLoad: translates['menu.online_load'],
          refill: translates['menu.refill'],
          refunds: translates['menu.refunds'],
          transactions: translates['menu.transactions'],
          appSupport: translates['menu.ap'],
        };


        this.translatesMachines = {
          machineStatus: translates['menu.machines_status'],
          tillMapping: translates['menu.till_mapping'],
          realtimesale: translates['menu.real_time_sale'],
          machineList: translates['menu.machines_list'],
          site: translates['menu.till_charge'],
          qrRequest: translates['menu.qr_request'],
          ticketCheck: translates['menu.ticket_check'],
          inventory: translates['menu.inventory'],
          inventoryManager: translates['menu.inventory_manager'],
          ocsAlerts: translates['menu.ocs_alerts'],
          tillsAlerts: translates['till_alert.title'],
          siteElora: translates['menu.site_elora'],
          iFreeVending: translates['menu.ifree_vend_manager']
        };

        this.onlineShopRoutesTranslates = {
          onlineShop: translates['menu.onlineShop'],
          ecommerceManager: translates['menu.ecommerce_manager'],
          checkOrders: translates['menu.check_orders'],
          ordersManager: translates['menu.orders_manager'],
        };

        this.routesRoutesTranslates = {
          salesModule: translates['menu.sales_module'],
          machinesModule: translates['menu.machines_module'],
        };

        this.articlesRoutesTranslates = {
          soldItems: translates['menu.sold_items'],
          top5sales: translates['menu.top5_sales'],
        };

        this.dataManagerRoutesTranslates = {
          operatorGroup: translates['menu.operator_group_manager'],
          operator: translates['menu.operator_manager'],
          clientGroup: translates['menu.client_group_manager'],
          client: translates['menu.client_manager'],
          site: translates['menu.site_manager'],
          till: translates['menu.till_manager'],
          article: translates['menu.article_manager'],
          supervisor: translates['menu.supervisor_manager'],
          routeDriver: translates['menu.stocker_manager'],
          route: translates['menu.route_manager'],
        };

        this.userManagementRoutesTranslates = {
          accessWeb: translates['menu.access_web'],
          accessQuery: translates['menu.access_query'],
          accessWebClient: translates['menu.access_web_client'],
        };

        this.qrRoutesTranslates = {
          generateQr: translates['menu.generateQr'],
          checkQr: translates['menu.checkQr'],
        };

        this.clickCollectRoutesTranslates = {
          clickCollect: translates['menu.click_collect'],
        };

        this.applicationManagementRoutesTranslates = {
          imageManager: translates['menu.image_manager'],
          notificationPanel: translates['menu.notification_panel'],
          notificationManager: translates['menu.notification_manager'],
          appFailedCharges: translates['menu.app_failed_charges'],
          requestLicensesVtag: translates['menu.request_licenses_vtag'],
          licensesVtagManager: translates['menu.licenses_vtag_manager'],
        };

        this.aenaTranslates = {
          assignArticleFamiliesAena:
            translates['menu.assign_articles_families_aena'],
          downloadFileAena: translates['menu.download_files_aena'],
          tillMapping: translates['menu.till_mapping'],
          sendFilesAena: translates['menu.send_file_aena'],
          checkArticleAena: translates['menu.check_article_aena'],
          transactionQuery: translates['menu.transactions_query'],
          articlesManagerAena: translates['menu.article_manager_aena'],
          checkSubgroupArticleAena:
            translates['menu.check_subgroup_article_aena'],
        };

        this.reportRoutesTranslates = { reports: translates['menu.reports'] };

        this.horecaQueryRoutesTranslate = {
          horecaQuery: translates['menu.horeca_query'],
          horecaDashboard: translates['menu.horeca_dashboard']
        };

        this.billingRoutesTranslates = {
          turnOver: translates['menu.turn_over'],
          collectionQuery: translates['menu.collection_query']
        };

        this.dashboardRourtesTranslate = {
          welcome: translates['menu.dashboard_welcome'],
          refilling: translates['menu.refilling_dashboard'],
          statusNet: translates['menu.status_net']
        }

        this.documentationRoutesTranslate = {
          documnentation: translates['menu.documentation_route'],
          newsAlliance: translates['menu.news_alliance'],
          newsNoPartner: translates['menu.news_no_partner'],
          newsPartner: translates['menu.news_partner'],
        }

        this.qPayRoutesTranslates = {
          transactionsQPay: translates['menu.transactions_qpay'],
          terminalsQPay: translates['menu.terminals_qpay']
        }

        this.routesWithoutGroup = [
          // { route: '/user/home', name: this.homeTranslate },
          { route: '/user/profile', name: this.profileTranslate },
        ];

        this.loadingTranslate = false;

        this.routeSearch = [];

        this.setRoutes();

        //support
        this.createTicketTranslate = translates['menu.create_support_ticket'];
        this.checkTicketTranslate = translates['menu.check_support_ticket'];
        this.supportCustomerTranslate = translates['menu.customer_support'];

        if (this.loadingPermissions) {
          this.startRouteGroup();
        } else {
          this.getRouteGroup();
        }
      });
  }

  orderLinkRoute(routes: LinkRoute[]) {
    routes.sort((a, b) =>
      a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
        ? 1
        : b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
        ? -1
        : 0
    );
  }

  orderGroup(routesGroup: LinkRouteGroup[]) {
    routesGroup.sort((a, b) =>
      a.nameGroup.toLocaleLowerCase() > b.nameGroup.toLocaleLowerCase()
        ? 1
        : b.nameGroup.toLocaleLowerCase() > a.nameGroup.toLocaleLowerCase()
        ? -1
        : 0
    );
  }

  startRouteGroup() {
    this.routeGroup = startGroupRoutes(this.groupRouteTranslates, this.company);
  }

  setRoutes() {
    this.groupRoutes = {
      query: getQueryRoutes(this.queryRoutesTranslates),
      cards: getCardRoutes(this.cardsRoutesTranslate),
      machines: getRoutesMachines(this.translatesMachines),
      articles: getArticlesRoutes(this.articlesRoutesTranslates),
      dataManager: getDataManagerRoutes(this.dataManagerRoutesTranslates),
      qr: getQRRoutes(this.qrRoutesTranslates),
      onlineShop: getOnlineShopRoutes(this.onlineShopRoutesTranslates),
      userManagement: getUserManagementRoutes(
        this.userManagementRoutesTranslates
      ),
      clickCollect: getClickCollectRoutes(this.clickCollectRoutesTranslates),
      applicationManagement: getApplicationManagementRoutes(
        this.applicationManagementRoutesTranslates
      ),
      aena: getAenaRoutes(this.aenaTranslates),
      listing: getReportRoutes(this.reportRoutesTranslates),
      routesManagement: getRoutesManagementRoutes(this.routesRoutesTranslates),
      horeca: getHorecaQueryRoutes(this.horecaQueryRoutesTranslate),
      billing: getBillingRoutes(this.billingRoutesTranslates),
      dashboards: getDashboardRoutes(this.dashboardRourtesTranslate),
      documentation: getDocumentation(this.documentationRoutesTranslate),
      qPay: getQPayRoutes(this.qPayRoutesTranslates)
    };
  }

  getValidsRoutes(permissions: Permission[]) {
    this.setRoutes();

    this.getRegisterCardRouteTranslate();

    this.routesValid = this.getStartRoutesValid();

    const groupName: Record<
      GroupNameRoutesDB,
      { routes: LinkRoute[]; name: GroupsNameRouteValids }
    > = this.getGroupRouteAndName();

    for (let permission of permissions) {
      if (groupName?.[permission.nameGroup]) {
        this.createValidGroup(
          groupName[permission.nameGroup].routes,
          groupName[permission.nameGroup].name,
          permission
        );
      }
    }
    this.getRouteGroup();
  }

  createValidGroup(
    routes: LinkRoute[],
    nameValid: string,
    permission: Permission
  ) {
    let index = routes.findIndex((per) => per.route == permission.route);

    if (index != -1 && !this.routesValid[nameValid].includes(index)) {
      this.routesValid[nameValid].push(index);
    }
  }

  getRouteGroup() {
    let validRoutes: Record<
      GroupsNameRouteValids,
      { routes: LinkRoute[]; valids: LinkRoute[]; order?: Function }
    > = this.getValidRoutes();

    for (const [key, value] of Object.entries(validRoutes)) {
      value.valids = generateRoutesGroupValid(
        value.routes,
        this.routesValid[key]
      );

      if (value?.order) {
        value.order(value.valids);
      }
    }

    this.orderByVisility(validRoutes.dataManager.valids);

    this.routeGroup = setGroupRoutes(
      this.groupRouteTranslates,
      this.company,
      validRoutes
    );

    this.orderGroup(this.routeGroup);
  }

  getSupportPermission() {
    let {
      loadedPermission,
      createTicket,
      checkTicket,
      customerSupport,
    } = this._permissionService.getSupport();

    if (loadedPermission) {
      this.createTicket = createTicket;
      this.checkTicket = checkTicket;
      this.supportCustomer = customerSupport;
    } else {
      let i = 0;
      let intervalWaitPermission = setInterval(() => {
        let {
          loadedPermission,
          createTicket,
          checkTicket,
          customerSupport,
        } = this._permissionService.getSupport();
        if (loadedPermission) {
          this.createTicket = createTicket;
          this.checkTicket = checkTicket;
          this.supportCustomer = customerSupport;
          clearInterval(intervalWaitPermission);
        }
        i++;
        if (i == 10) {
          clearInterval(intervalWaitPermission);
        }
      }, 1000);
    }
  }

  compareTextInsensitive(url, search) {
    let urlInsensitive = this.genereateInsensitive(url);
    let searchInsensitive = this.genereateInsensitive(search);
    return urlInsensitive.includes(searchInsensitive);
  }

  genereateInsensitive(text: string) {
    let textInsensitive = (text || '').toLowerCase();
    textInsensitive = textInsensitive.replace(/[àáâãäå]/, 'a');
    textInsensitive = textInsensitive.replace(/[eéèëê]/, 'e');
    textInsensitive = textInsensitive.replace(/['iíìïî']/, 'i');
    textInsensitive = textInsensitive.replace(/['oóòõöô']/, 'o');
    textInsensitive = textInsensitive.replace(/['uúùüû']/, 'u');
    textInsensitive = textInsensitive.replace(/['ç']/, 'c');
    return textInsensitive;
  }

  orderByVisility(routes: LinkRoute[]) {
    routes.sort(
      (a, b) =>
        this.orderVisibilityRoutes.findIndex(
          (orderVisibility) => orderVisibility === a?.route
        ) -
        this.orderVisibilityRoutes.findIndex(
          (orderVisibility) => orderVisibility === b?.route
        )
    );
  }

  backSelectRole() {
    this.navCtrl.navigateRoot('/user/select-role', { animated: true });
  }

  getRouteAndName(group: GroupsNameRouteValids) {
    return { routes: this.groupRoutes[group], name: group };
  }

  getGroupRouteAndName() {
    let groupName: Partial<
      Record<
        GroupNameRoutesDB,
        { routes: LinkRoute[]; name: GroupsNameRouteValids }
      >
    > = {};

    for (let nameRouteDB in GROUP_NAME_ROUTES_DB) {
      groupName[GROUP_NAME_ROUTES_DB[nameRouteDB]] = this.getRouteAndName(
        nameRouteDB as GroupsNameRouteValids
      );
    }

    return groupName as Record<
      GroupNameRoutesDB,
      { routes: LinkRoute[]; name: GroupsNameRouteValids }
    >;
  }

  getStartRoutesValid() {
    const startRoutesValid: Partial<
      Record<GroupsNameRouteValids, number[]>
    > = {};

    for (let names in GROUP_NAME_ROUTES_DB) {
      startRoutesValid[names] = [];
    }

    return startRoutesValid as Record<GroupsNameRouteValids, number[]>;
  }

  getValidRoutes() {
    let validRoutes: Record<
      string,
      { routes: LinkRoute[]; valids: LinkRoute[]; order?: Function }
    > = {};

    for (let name in GROUP_NAME_ROUTES_DB) {
      validRoutes[name] = {
        routes: this.groupRoutes[name],
        valids: [],
      };
      if (!NOT_ORDER_GROUP_ROUTES.includes(name as GroupsNameRouteValids)) {
        validRoutes[name] = {
          ...validRoutes[name],
          order: this.orderLinkRoute,
        };
      }
    }
    return validRoutes;
  }

  getRegisterCardRouteTranslate() {
    const {
      loadPermission,
      isAssignedClient,
      isCreate,
    } = this._permissionService.getRegisterCard();

    const previuous = this.isRegisterCardTranslate;
    this.registerCardRouteTranslate = this.registerCardsTranslate;
    this.isRegisterCardTranslate = true;

    if (loadPermission && isAssignedClient && !isCreate) {
      this.registerCardRouteTranslate = this.assignCardsTranslate;
      this.isRegisterCardTranslate = false;
    }

    if (previuous !== this.isRegisterCardTranslate) {
      setTimeout(() => {
        this.savePlaceholders();
      }, 100);
    }
  }

}
