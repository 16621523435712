export const TYPE_SELECTED_DESCRIPTION_TRANSLATE: { [x: string]: string } = {
  'Operator group': 'operator',
  Operator: 'client',
  Client: 'till',
  Machine: 'article',
  null: 'operator',
};

export const SIZE_PC_TO = 1100;

export const DIFF_MAX_DAYS_THREE_MONTHS_TURN_OVER = 95;
export const DIFF_MAX_DAYS_YEAR_TURN_OVER = 397;

export const TURN_OVER_LOW_LEVEL_NAME = 'Low Level: Turnover';
export const TURN_OVER_HIGH_LEVEL_NAME = 'High Level: Turnover';
export const TURN_OVER_ROUTE = '/user/turn-over';


export const SHOW_TOTALS_BUTTON_TURN_OVER = 1550;
export const CHANGE_POSITION_BUTTONS_TURN_OVER = 992;
