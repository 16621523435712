export interface BillingRoutesTranslates {
  turnOver: string,
  collectionQuery: string
}

export const getBillingRoutes = (
  translates: BillingRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/turn-over', name: translates.turnOver },
    { route: '/user/collection-query', name: translates.collectionQuery },
  ]
}
